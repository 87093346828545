import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import AspectRatioRounded from '@mui/icons-material/AspectRatioRounded';
import { Box, IconButton, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { USER_TYPE } from '../../../../utils/constants';
import useSyncMobileDevices from '../../../../hooks/useSyncMobileDevices';
import SyncMobileDevicesModal from './SyncMobileDevicesModal';
import CardMedia from '@mui/material/CardMedia';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import { useState } from 'react';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { LocalShippingRounded, PieChartOutlineOutlined } from '@mui/icons-material';
import { epicorSyncShipsTo } from '../../../../store/actions/epicor/epicorSyncShipsTo';

const syncMobilePermitedRoles = [
  USER_TYPE.PREMIUM_ADMIN,
  USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN,
  USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER
];

const SyncMobileDevices = () => {
  const [open, setOpen] = useState(false);
  const [openSyncShipsTo, setOpenSyncShipsTo] = useState(false);
  const classes = styles();
  const user = useSelector((state) => state.currentUser);
  const epicorBins = useSelector((state) => state.epicorBins);
  const syncMobileDevices = useSelector((state) => state.epicorSyncMobileDevices);
  const dispatch = useDispatch();
  const isAdminUser = syncMobilePermitedRoles.includes(user.type);

  if (!isAdminUser) {
    return null;
  }

  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  const handleOpenModal = () => {
    setOpen(true);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SYNC_MOBILE_DATA_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SYNC_MOBILE_DATA_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleSyncMobileDevices = useSyncMobileDevices();

  const handleOpenSyncShipsToModal = () => {
    setOpenSyncShipsTo(true);
    dispatch({
      type: REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleCloseSyncShipsToModal = () => {
    setOpenSyncShipsTo(false);
    dispatch({
      type: REDUX_ACTIONS.EPICORE_RIGS_SYNC_SHIPSTO_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleSyncShiptsTo = () => {
    dispatch(epicorSyncShipsTo());
  };

  return <Box>
    <Box className={classes.container}>
    {user.type !== USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER && <Box>
        <IconButton
          onClick={() => {
            handleOpenModal();
          }}
          disabled={epicorBins.loading === true}
        >
          <SyncRoundedIcon />
        </IconButton>
      </Box>}
      { user.type !== USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER && <Box>
        <IconButton
          onClick={() => {
            handleOpenSyncShipsToModal();
          }}
          disabled={epicorBins.loading === true}
        >
          <LocalShippingRounded />
        </IconButton>
      </Box> }
      <Box>
        <IconButton
          onClick={() => {
            window.open(
              '/FieldBoxes/KPIs',
              '_blank'
            );
          }}
          disabled={epicorBins.loading === true}
        >
          <PieChartOutlineOutlined />
        </IconButton>
      </Box>
    </Box>
      {open
        ? (
        <SyncMobileDevicesModal
        open={open}
        onClose={handleCloseModal}
        handleClose={handleCloseModal}
        cta2Label={'Sync'}
        cta2Disabled={syncMobileDevices.syncStatus === 'loading'}
        cta2Action={handleSyncMobileDevices}
        showCta2={syncMobileDevices.syncResponseMessage !== 'Sync Completed'}
        showDateTimeRange={false}
      >
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />

        <Typography variant="h3" gutterBottom component="div" className={classes.title}>
          Sync Mobile Devices?
        </Typography>

        { syncMobileDevices.syncResponseMessage !== ''
          ? <Typography variant="h5" gutterBottom component="div" className={classes.statusMessage}>
            {syncMobileDevices.syncResponseMessage}
          </Typography>
          : <div style={{
            height: 40,
          }}></div>
        }
      </SyncMobileDevicesModal>
          )
        : null}
      {openSyncShipsTo
        ? (
        <SyncMobileDevicesModal
        open={openSyncShipsTo}
        onClose={handleCloseSyncShipsToModal}
        handleClose={handleCloseSyncShipsToModal}
        cta2Label={'Sync'}
        cta2Disabled={syncMobileDevices.syncShipsToStatus === 'loading'}
        cta2Action={handleSyncShiptsTo}
        showCta2={syncMobileDevices.syncShipsToResponseMessage !== 'Sync Completed'}
        showDateTimeRange={false}
      >
        <CardMedia
          component="img"
          image={Premium}
          className={classes.logo}
        />

        <Typography variant="h3" gutterBottom component="div" className={classes.title}>
          Sync ShipTo ?
        </Typography>

        { syncMobileDevices.syncShipsToResponseMessage !== ''
          ? <Typography variant="h5" gutterBottom component="div" className={classes.statusMessage}>
            {syncMobileDevices.syncShipsToResponseMessage}
          </Typography>
          : <div style={{
            height: 40,
          }}></div>
        }
      </SyncMobileDevicesModal>
          )
        : null}
    </Box>;
};

export default SyncMobileDevices;
