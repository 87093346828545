import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { REDUX_ACTIONS } from '../../../../../shared/config/constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CancelPresentationRounded, DirectionsRun } from '@mui/icons-material';
import styles from './style';
import { getDateInfo } from '../../../../../utils/dateInfo';
import { dataLoading } from '../../../../../store/actions/dataLoading';
import { Button, Fade, Grid, Modal, TextField, Box } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { enqueueSnackbar } from '../../../../../store/actions/snackbar';
import { CALENDER_PROPS } from '../../../../../utils/constants';
import { format, parse } from 'date-fns';

const DateRangeModal = () => {
  const { assetData } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = styles();
  const lastDateInRecord = new Date(format(parse(assetData.data.at(-1).timestamp, 'MM/dd/yyyy HH:mm:ss', new Date()), 'MM/dd/yyyy HH:mm'));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [timeDiff, setTimeDiff] = useState(0);

  React.useEffect(() => {
    const updateStartDate = new Date(lastDateInRecord);
    updateStartDate.setDate(updateStartDate.getDate() - 1);
    setStartDate(updateStartDate);
    setEndDate(lastDateInRecord);
  }, []);

  useEffect(() => {
    const currentStartDate = new Date(startDate);
    const currentEndDate = new Date(endDate);
    if (startDate && endDate) {
      setTimeDiff((currentEndDate.getTime() - currentStartDate.getTime()) / 1000);
    }
  }, [startDate, endDate]);

  const handleClose = () => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_TOOGLE_DATES_MODAL,
      payload: false,
    });
  };
  const handleApply = () => {
    if (endDate > lastDateInRecord) {
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    if (timeDiff > 90000 || startDate > endDate) { // return if time diff is greater than 24 hrs with buffer to DST
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const formattedStartDate = getDateInfo(startDate, '/').formattedDayTime;
    const formattedEndDate = getDateInfo(endDate, '/').formattedDayTime;
    dispatch(dataLoading(true));
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_SET_DATES,
      payload: {
        dateRange: {
          start: formattedStartDate,
          end: formattedEndDate,
        },
      },
    });
    handleClose();
  };
  const handleStartDateUpdate = (date) => {
    setStartDate(date);
  };
  const handleEndDateUpdate = (date) => {
    setEndDate(date);
  };

  const adjustSeconds = (date, type, timeDiff) => {
    const newDate = new Date(date);
    const currentSeconds = newDate.getSeconds();

    const adjustedSeconds = type === 'increase'
      ? currentSeconds + timeDiff
      : currentSeconds - timeDiff;

    newDate.setSeconds(adjustedSeconds);
    return newDate;
  };

  const handleTimeShift = (type) => {
    if (timeDiff > 90000 || startDate > endDate) { // return if time diff is greater than 24 hrs with buffer to DST
      dispatch(
        enqueueSnackbar(
          'Please select valid end date',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return;
    }
    const currentStartDate = new Date(startDate);
    const currentEndDate = new Date(endDate);
    setStartDate(adjustSeconds(currentStartDate, type, timeDiff));
    setEndDate(adjustSeconds(currentEndDate, type, timeDiff));
  };

  return (
    <Modal
      open={assetData.isDateRangeModalOpen}
      onClose={handleClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={assetData.isDateRangeModalOpen}>
        <div className={classes.paper}>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            {startDate && endDate && (
            <Box>
            <Button
              onClick={() => handleTimeShift('decrease')}
            >
              <FastRewindIcon fontSize='large' htmlColor ='DimGray'/>
            </Button>
            <Button
                onClick={() => handleTimeShift('increase')}
            >
              <FastForwardIcon fontSize='large' htmlColor ='DimGray'/>
            </Button>
            </Box>
            )}
            <Box className={classes.container}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  {...CALENDER_PROPS}
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateUpdate}
                  className={classes.dateTimePicker}
                  classes={{
                    switchHeader: classes.datePickerHeader,
                  }}
                  textField={(params) => <TextField {...params} />}
                />
                <DateTimePicker
                  {...CALENDER_PROPS}
                  label="End Date"
                  value={endDate}
                  maxDateTime={lastDateInRecord}
                  onChange={handleEndDateUpdate}
                  className={classes.dateTimePicker}
                  textField={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Button
              onClick={handleApply}
              disabled={!startDate || !endDate || startDate >= endDate}
            >
              <DirectionsRun fontSize='large' htmlColor ='DimGray'/>
            </Button>
            <Button
              className={classes.closeModalButton}
              onClick={handleClose}
            >
              <CancelPresentationRounded fontSize='large' htmlColor ='DimGray'/>
            </Button>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
};
export default DateRangeModal;
