const columns = (renderCell, renderJsonField) => [
  {
    field: 'userEmail',
    headerName: 'Email ID',
    width: 250,
    hide: false,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params.row.details?.userEmail || 'No data'
  },
  {
    field: 'ip',
    headerName: 'IP',
    width: 300,
    renderCell,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => params.row.details.payload.user?.ip || 'No data'
  },
  {
    field: 'lastUpdate',
    headerName: 'Timestamp',
    width: 250,
    hide: false,
    renderCell,
    headerAlign: 'center'
  },
  {
    field: 'details',
    headerName: 'Details',
    width: 300,
    renderCell: renderJsonField,
    headerAlign: 'center',
    align: 'center',
    sortable: false
  }
];

export default columns;
