import { UNIT_SYSTEM } from '../../../shared/config/hydraulics_constants';

const columns = (renderCell, renderValueCell, renderActions, drillFluidDetailsUnit, currentUnit) => [
  {
    field: 'mw',
    headerName: `MW (${drillFluidDetailsUnit?.mw?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillFluidDetailsUnit?.mw?.decimals));
    },
  },
  {
    field: 'fann_600',
    headerName: 'FANN600',
    width: 112,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fann_300',
    headerName: 'FANN300',
    width: 112,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fann_200',
    headerName: 'FANN200',
    width: 112,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fann_100',
    headerName: 'FANN100',
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fann_6',
    headerName: 'FANN6',
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'fann_3',
    headerName: 'FANN3',
    width: 120,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'pv',
    headerName: `PV (${drillFluidDetailsUnit?.pv?.label})`,
    width: 120,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.fann_600 && params?.row?.fann_300 && (params?.row?.fann_300 < params?.row?.fann_600) && (params?.row?.fann_200 < params?.row?.fann_300)) {
        const pv = params.row.fann_600 - params.row.fann_300;
        return pv.toFixed(drillFluidDetailsUnit?.pv?.decimals);
      }
      return '';
    }
  },
  {
    field: 'yp',
    headerName: `YP (${drillFluidDetailsUnit?.yp?.label})`,
    width: 150,
    headerAlign: 'center',
    align: 'center',
    valueGetter: params => {
      if (params?.row?.fann_300 && params?.row?.fann_600 && (params?.row?.fann_300 < params?.row?.fann_600) && (params?.row?.fann_200 < params?.row?.fann_300)) {
        const yp = params.row.fann_300 - (params.row.fann_600 - params.row.fann_300);
        return currentUnit !== UNIT_SYSTEM.US ? yp.toFixed(drillFluidDetailsUnit?.yp?.decimals) * parseFloat(drillFluidDetailsUnit?.yp?.conversionFactor).toFixed(drillFluidDetailsUnit?.yp?.decimals) : yp.toFixed(drillFluidDetailsUnit?.yp?.decimals);
      }
      return '';
    }
  },
  {
    field: 'gel_10sec',
    headerName: `GEL 10 SEC (${drillFluidDetailsUnit?.gel_10sec?.label})`,
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(drillFluidDetailsUnit?.gel_10sec?.decimals));
    },
  },
  {
    field: 'gel_10min',
    headerName: `GEL 10 MIN (${drillFluidDetailsUnit?.gel_10min?.label})`,
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(drillFluidDetailsUnit?.gel_10min?.decimals);
    },
  },
  {
    field: 'tank_vol',
    headerName: `TANK VOLUME (${drillFluidDetailsUnit?.tank_vol?.label})`,
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(drillFluidDetailsUnit?.tank_vol?.decimals);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
