import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    width: '600px',
  },
  logo: {
    width: '70%',
    margin: 'auto',
  },
  statusMessage: {
    textAlign: 'center',
    paddingBottom: '45px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '-12px',
    marginLeft: '12px'
  }
}));

export default styles;
