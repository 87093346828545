import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const columns = (renderValueCell, renderActions, cuttingData, currentUnit) => [
  {
    field: 'diameter',
    headerName: `Diameter (${cuttingData?.diameter.label})`,
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(cuttingData?.diameter?.decimals));
    },
  },
  {
    field: 'thickness',
    headerName: `Thickness (${cuttingData?.thickness.label})`,
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(cuttingData?.thickness?.decimals));
    },
  },
  {
    field: 'density',
    headerName: `Density (${cuttingData?.density.label})`,
    width: 180,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(cuttingData?.density?.decimals));
    },
  },
  {
    field: 'porosity',
    headerName: `Porosity (${cuttingData?.porosity.label})`,
    width: 185,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : parseFloat(value.toFixed(cuttingData?.porosity?.decimals));
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
