import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const columns = (renderCell, runDetailsUnit, currentUnit) => [
  {
    field: 'hole_depth',
    headerName: `Hole Depth (${runDetailsUnit?.hole_depth?.label})`,
    width: 210,
    renderEditCell: renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(runDetailsUnit?.hole_depth?.decimals);
    },
  },
  {
    field: 'bit_depth_offset',
    headerName: `Bit Off-Bottom Offset (${runDetailsUnit?.bit_depth_offset?.label})`,
    width: 300,
    renderEditCell: renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(runDetailsUnit?.bit_depth_offset?.decimals);
    },
  },
  {
    field: 'rop',
    headerName: `ROP (${runDetailsUnit?.rop?.label})`,
    width: 180,
    renderEditCell: renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(runDetailsUnit?.rop?.decimals);
    },
  },
  {
    field: 'rpm',
    headerName: `RPM (${runDetailsUnit?.rpm?.label})`,
    width: 180,
    renderEditCell: renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(runDetailsUnit?.rpm?.decimals);
    },
  },
  {
    field: 'flow_in',
    headerName: `Flow Rate (${runDetailsUnit?.flow_in?.label})`,
    width: 215,
    renderEditCell: renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(runDetailsUnit?.flow_in?.decimals);
    },
  },
  {
    field: 'sbp',
    headerName: `SBP (${runDetailsUnit?.sbp?.label})`,
    width: 200,
    renderEditCell: renderCell,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(runDetailsUnit?.sbp?.decimals);
    },
  },
  {
    field: 'drillStringsId',
    headerName: 'Drillstrings',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'drillFluidsId',
    headerName: 'Drilling Fluids',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'trip_data',
    headerName: 'Stripping',
    width: 200,
    renderCell,
    headerAlign: 'center',
    align: 'center',
  }
];
export default columns;
