import { useEffect, useState } from 'react';
import { DirectionsRunRounded, AspectRatioRounded, FastRewind, FastForward } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  TextField,
  FormControl,
  FilledInput,
  InputLabel,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMarkeplaceTransactionList } from '../../../../store/actions/marketplaceTransactions';
import { CALENDER_PROPS } from '../../../../utils/constants';

const MarketplaceTransactionControls = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const location = useLocation();
  const marketplaceTransactions = useSelector((state) => state.marketplaceTransactions);

  const { preferences } = useSelector((state) => state.currentUser);

  const [userEmailTerm, setUserEmailTerm] = useState(null);
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEnDateTime] = useState(null);

  const handleUserEmailTerm = (e) => {
    setUserEmailTerm(e.target.value);
  };
  const handleStartTime = (date) => {
    setSelectedStartDateTime(date);
  };
  const handleEndTime = (date) => {
    setSelectedEnDateTime(date);
  };

  const handleSearch = () => {
    dispatch(
      getMarkeplaceTransactionList(
        userEmailTerm,
        selectedStartDateTime,
        selectedEndDateTime
      )
    );
  };

  const handleAspectRatio = useAspectRatio();

  useEffect(() => {
    const startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const endDate = new Date();
    setUserEmailTerm(null);
    setSelectedStartDateTime(startDate);
    setSelectedEnDateTime(endDate);
    dispatch(
      getMarkeplaceTransactionList(
        userEmailTerm,
        startDate,
        endDate
      )
    );
  }, [location.pathname]);

  const calculateTimeDiff = (start, end) => {
    return Math.abs(new Date(end).getTime() - new Date(start).getTime());
  };

  const handleTimeShift = (type) => {
    let updatedStartDateTime = selectedStartDateTime ? new Date(selectedStartDateTime) : null;
    let updatedEndDateTime = selectedEndDateTime ? new Date(selectedEndDateTime) : null;

    if (updatedStartDateTime && updatedEndDateTime) {
      const timeDiff = calculateTimeDiff(updatedStartDateTime, updatedEndDateTime);

      if (type === 'increase') {
        updatedStartDateTime = new Date(updatedStartDateTime.getTime() + timeDiff);
        updatedEndDateTime = new Date(updatedEndDateTime.getTime() + timeDiff);

        if (updatedStartDateTime > new Date()) {
          return;
        }
      } else {
        updatedStartDateTime = new Date(updatedStartDateTime.getTime() - timeDiff);
        updatedEndDateTime = new Date(updatedEndDateTime.getTime() - timeDiff);
      }

      setSelectedStartDateTime(updatedStartDateTime);
      setSelectedEnDateTime(updatedEndDateTime);
    }
  };

  const isForwardDisabled = () => {
    if (selectedStartDateTime && selectedEndDateTime) {
      const timeDiff = calculateTimeDiff(selectedStartDateTime, selectedEndDateTime);
      const futureStartDate = new Date(selectedStartDateTime.getTime() + timeDiff);
      return futureStartDate > new Date();
    }
    return false;
  };

  return (
    <Grid alignItems="flex-end" justifyContent="flex-end" sx={{ marginLeft: 'auto' }}>
      <Box className={classes.container}>
        <Box>
        <FormControl>
          <InputLabel
            htmlFor="outlined-adornment-search"
            inputprops={{ classes: { root: classes.searchBar } }}
          >
            User Email
          </InputLabel>
          <FilledInput
            className={classes.searchBar}
            id="outlined-adornment-search"
            type="text"
            label="User Email"
            value={userEmailTerm || ''}
            onChange={handleUserEmailTerm}
          />
        </FormControl>
        </Box>
        <Box>
          <IconButton onClick={() => handleTimeShift('decrease')} disabled={marketplaceTransactions?.loading || (selectedStartDateTime > selectedEndDateTime)}>
            <FastRewind />
          </IconButton>
          <IconButton onClick={() => handleTimeShift('increase')} disabled={marketplaceTransactions?.loading || isForwardDisabled() || (selectedStartDateTime > selectedEndDateTime)}>
            <FastForward />
          </IconButton>
        </Box>
        <Box className={classes.dateContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...CALENDER_PROPS}
            label="Start Date"
            disableFuture
            value={selectedStartDateTime}
            onChange={handleStartTime}
            className={classes.dateTimePicker}
            classes={{
              switchHeader: classes.datePickerHeader,
            }}
            textField={(params) => <TextField {...params} />}
          />
          <DateTimePicker
            {...CALENDER_PROPS}
            label="End Date"
            disableFuture
            minDate={selectedStartDateTime}
            value={selectedEndDateTime}
            onChange={handleEndTime}
            className={classes.dateTimePicker}
            disabled={!selectedStartDateTime}
            textField={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </Box>
        <Box className={classes.runActionContainer}>
          <IconButton
            size="large"
            aria-label="orders"
            onClick={handleSearch}
            disabled={isForwardDisabled() || (selectedStartDateTime > selectedEndDateTime) || (selectedEndDateTime > new Date())}
          >
            <DirectionsRunRounded />
          </IconButton>
          </Box>
          <Box className={classes.aspectRatioContainer}>
          <IconButton
            edge="end"
            onClick={() => {
              handleAspectRatio(!preferences.aspectChanged);
            }}
          >
            <AspectRatioRounded />
          </IconButton>
          </Box>
      </Box>
    </Grid>
  );
};

export default MarketplaceTransactionControls;
