/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Box, CircularProgress, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, ChevronLeft, ChevronRight } from '@mui/icons-material';
import CanvasJSReact from '../../canvasjs/canvasjs.react';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import { useDispatch, useSelector } from 'react-redux';
import { listMudDisplacement } from '../../../../store/actions/hydraulics';
import { HYDRAULICS_CHART_COLORS } from '../../../../shared/config/hydraulics_constants';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MudDisplacementChart = ({
  onCloseClick,
  isOpen,
  rowData,
  wellDetails
}) => {
  const classes = styles();
  const [tableRow, setTableRows] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { mudDisplacement } = useSelector((state) => state.hydraulics);
  const dispatch = useDispatch();

  useEffect(() => {
    const body = {
      runId: rowData?.row?._id,
      wellsInfoId: rowData?.row?.wellsInfoId
    };
    dispatch(listMudDisplacement(body));
  }, []);

  useEffect(() => {
    if (mudDisplacement?.data[0]?.results?.length) {
      const records = mudDisplacement?.data[0]?.results?.map((record) => ({
        ...record,
        _id: Math.random().toString(36).substring(2, 10),
      }));
      setTableRows(records);
    } else {
      setTableRows([]);
    }
  }, [mudDisplacement]);

  const options = {
    height: 700,
    animationEnabled: true,
    theme: 'light2',
    backgroundColor: '#FFF',
    legend: {
      fontSize: 18,
    },
    axisY: {
      title: 'Strokes',
      includeZero: true,
      reversed: true,
      titleFontSize: 22,
      labelFontSize: 16,
      gridThickness: 1,
    },
    axisX: {
      title: `Pressure (${mudDisplacement?.units?.sbp?.label})`,
      minimum: 0,
      titleFontSize: 22,
      labelFontSize: 16,
      gridThickness: 1,
    },
    axisX2: {
      title: `Flow Rate (${mudDisplacement?.units?.flow_rate?.label})`,
      titleFontSize: 22,
      labelFontSize: 16,
      minimum: 0,
      gridThickness: 1,
      labelPlacement: 'outside',
      labelFormatter: function (e) {
        return Math.round(e.value);
      },
    },
    data: [
      {
        type: 'line',
        name: `SBP (${mudDisplacement?.units?.sbp?.label})`,
        axisXType: 'primary',
        showInLegend: true,
        lineThickness: 1,
        color: HYDRAULICS_CHART_COLORS.DISPLACEMENT_SBP,
        toolTipContent: `Strokes: {y} <br/> SBP: {x} ${mudDisplacement?.units?.sbp?.label}`,
        dataPoints: mudDisplacement?.data[0]?.results?.map((record) => ({
          x: record.sbp,
          y: record.strokes,
        })) || [],
      },
      {
        type: 'line',
        name: `Static SBP (${mudDisplacement?.units?.static_sbp?.label})`,
        axisXType: 'primary',
        showInLegend: true,
        lineThickness: 1,
        color: HYDRAULICS_CHART_COLORS.DISPLACEMENT_STATIC_SBP,
        toolTipContent: `Strokes: {y} <br/> Static SBP: {x} ${mudDisplacement?.units?.static_sbp?.label}`,
        dataPoints: mudDisplacement?.data[0]?.results?.map((record) => ({
          x: record.static_sbp,
          y: record.strokes,
        })) || [],
      },
      {
        type: 'line',
        axisXType: 'secondary',
        name: `Flow Rate (${mudDisplacement?.units?.flow_rate?.label})`,
        showInLegend: true,
        lineThickness: 1,
        color: HYDRAULICS_CHART_COLORS.DISPLACEMENT_FLOW_RATE,
        toolTipContent: `Strokes: {y} <br/>Flow Rate: {x} ${mudDisplacement?.units?.flow_rate?.label}`,
        dataPoints: mudDisplacement?.data[0]?.results?.map((record) => ({
          x: record.flow_rate,
          y: record.strokes,
        })) || [],
      },
    ],
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseClick}
      maxWidth={'lg'}
      fullWidth={true}
      style={{
        marginRight: '20px'
      }}
      PaperProps={{
        sx: {
          overflow: 'visible'
        },
      }}
      className={classes.dialogModal}
    >
      <div className={classes.dialogTitle}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {`${wellDetails?.Rig} | ${wellDetails?.Well} | ${rowData?.row?.name}`}
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  onCloseClick();
                }}
              >
                <CancelPresentationRounded fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <Divider />
      <Grid container item xs={showTable ? 7 : 12} alignItems="center" justifyContent="center">
        <Grid item style={{ fontWeight: 'bold', fontSize: '1.3rem', marginTop: showTable ? '3%' : '2.5%' }}>
          {'CBHP Rollover'}
        </Grid>
      </Grid>
      <Box style={{
        overflow: 'auto',
        marginTop: '-2%'
      }}>
        <Grid container marginBottom={'2%'}>
          <Grid item xs={showTable ? 7 : 12} sx={{
            p: 2,
            pt: 10,
          }}>
            {mudDisplacement.loading
              ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ height: '700px' }}
                >
                  <CircularProgress />
                </Box>
                )
              : (
                <CanvasJSChart options={options} />
                )}
          </Grid>
          {!showTable && <div className={classes.menuTogglerBtn}>
            <IconButton
              onClick={() => {
                setShowTable(!showTable);
              }}>
              <ChevronLeft sx={{ fontSize: '33px' }} />
            </IconButton>
          </div>}
          {showTable && <Grid item xs={5} marginTop={'2.5%'}>
            <div className={classes.menuTogglerTableBtn}>
              <IconButton
                onClick={() => {
                  setShowTable(!showTable);
                }}>
                <ChevronRight sx={{ fontSize: '33px' }} />
              </IconButton>
            </div>
            {showTable && <Grid item xs={12}>
              <Datagrid
                fileName={`${wellDetails?.Well?.trim()}-${rowData?.row?.name?.trim()}-Mud-Displacement`}
                loading={mudDisplacement.loading}
                data={tableRow}
                sx={{
                  '.MuiDataGrid-cell': {
                    fontSize: '1rem',
                    padding: '2px',
                  },
                  '.MuiDataGrid-columnHeader': {
                    fontSize: '1.2rem',
                    fontWeight: '900 !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    overflow: 'visible',
                    lineHeight: '1.43rem',
                    whiteSpace: 'normal',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    minHeight: '0px'
                  },
                }}
                columns={columns(mudDisplacement?.units)}
                disableStickyHeader={true}
                autoHeight={false}
                hideFooterPagination
                hideFooterSelectedRowCount
                height={'800px'}
                getRowHeight={() => 'auto'}
                pageSize={100}
                toolbar={{
                  options: {
                    columns: false,
                    filters: false,
                    density: false,
                    export: {
                      enabled: true,
                    }
                  },
                }}
              />
            </Grid>}
          </Grid>}
        </Grid>
      </Box>
    </Dialog>
  );
};
MudDisplacementChart.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  rowData: PropTypes.any,
  wellDetails: PropTypes.any
};
MudDisplacementChart.defaultProps = {
  selectedJob: {}
};

export default MudDisplacementChart;
