import { REDUX_ACTIONS } from '../../../shared/config/constants';

const initialState = {
  binRemindersStatus: 'idle', // idle, loading, error
  binRemindersResponseMessage: ''
};

const epicorSendBinReminders = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_LOADING:
      return {
        ...state,
        binRemindersStatus: 'loading',
        binRemindersResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_IDLE:
      return {
        ...state,
        binRemindersStatus: 'idle',
        binRemindersResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_ERROR:
      return {
        ...state,
        binRemindersStatus: 'error',
        binRemindersResponseMessage: action.payload.message,
      };
    case REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_SUCCESS:
      return {
        ...state,
        binRemindersStatus: 'success',
        binRemindersResponseMessage: action.payload.message,
      };
    default:
      return state;
  }
};

export default epicorSendBinReminders;
