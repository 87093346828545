import { modifyMetricData } from '../../utils/getMetrics';
import { UNIT_SYSTEM } from '../../shared/config/hydraulics_constants';

const columns = (renderCell, RenderJsonField, renderActions, renderValueCell, renderValueTextCell, drillStringUnit, currentUnit) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderEditCell: renderValueTextCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'MD',
    headerName: `MD (${drillStringUnit?.MD?.label})`,
    width: 200,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(drillStringUnit?.MD.decimals);
    },
  },
  {
    field: 'drillstring_details',
    headerName: 'Drillstring',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      const filteredData = Array.isArray(data?.value) && data?.value?.length
        ? data.value.map((item) => ({
          name: item.name,
          id: item.id,
          od: item.od,
          tj_od: item.tj_od,
          len: item.len,
          tj_id: item.tj_id,
          tj_len: item['tj_len%'],
        }))
        : [];

      const convertedData = modifyMetricData(filteredData, drillStringUnit?.details, currentUnit);
      return <RenderJsonField value={convertedData} />;
    }
  },
  {
    field: 'bit_details',
    headerName: 'Bit',
    headerAlign: 'center',
    align: 'center',
    width: 300,
    renderCell: (data) => {
      if (!data?.value || (Array.isArray(data.value) && data.value.length === 0)) {
        return <RenderJsonField value={{}} />;
      }

      const filteredData = {
        size: currentUnit !== UNIT_SYSTEM.US
          ? parseFloat(
            (data.value.size * drillStringUnit?.bit_details?.size?.conversionFactor).toFixed(drillStringUnit?.bit_details?.size?.decimals)
          )
          : data.value.size,
        tfa: currentUnit !== UNIT_SYSTEM.US
          ? parseFloat(
            (data.value.tfa * drillStringUnit?.bit_details?.tfa?.conversionFactor).toFixed(drillStringUnit?.bit_details?.tfa?.decimals)
          )
          : data.value.tfa,
        type: data.value.type,
        nozzles: Array.isArray(data?.value?.nozzles)
          ? data.value.nozzles.map((nValue) => {
            return parseFloat(
              (nValue * drillStringUnit?.bit_details?.nozzles?.conversionFactor).toFixed()
            );
          })
          : [],
      };

      return <RenderJsonField value={filteredData} />;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
