import { UNIT_SYSTEM } from '../../shared/config/hydraulics_constants';

const columns = (renderCell, renderActions, renderTypeCell, renderValueCell, geometryUnit, currentUnit) => [
  {
    field: 'type',
    headerName: 'Type',
    width: 250,
    renderEditCell: renderTypeCell,
    editable: true,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'top_md',
    headerName: `Start MD (${geometryUnit?.top_md?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(geometryUnit?.top_md?.decimals);
    },
  },
  {
    field: 'shoe_md',
    headerName: `End MD (${geometryUnit?.shoe_md?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(geometryUnit?.shoe_md?.decimals);
    },
  },
  {
    field: 'id',
    headerName: `ID (${geometryUnit?.id?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(geometryUnit?.id?.decimals);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
