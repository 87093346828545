/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from '../../../../store/actions/snackbar';
import { Grid, IconButton, Typography } from '@mui/material';
import { CancelPresentationRounded, DeleteRounded, SaveRounded } from '@mui/icons-material';
import SelectEditInputTextField from '../../SelectEditInputTextField';
import ModalDialog from '../../ModalDialog';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import PropTypes from 'prop-types';
import { HYDRAULICS_TRIP_PIPE_TYPE, HYDRAULICS_TRIP_PIPE_TYPE_VALUE, HYDRAULICS_TRIP_STRIPPING_TYPE_VALUE, HYDRAULICS_TRIP_TYPE } from '../../../../utils/constants';
import SelectEditValueTypes from '../../SelectEditValueTypes';
import DeleteConfirmationModal from '../../../deleteConfirmationModal';
import { addNewStrippingRow, createStrippingData, deleteStrippingRow, listStrippingData, updateStrippingData } from '../../../../store/actions/hydraulicsStripping';
import useUnsavedChanges from '../../../../hooks/useUnsavedChanges';
import useUnitConversion from '../../../../hooks/useUnitConversion';
import { UNIT_SYSTEM } from '../../../../shared/config/hydraulics_constants';

const TripPropertyModal = ({
  onCloseClick,
  isOpen,
  selectedJob,
  data
}) => {
  const classes = styles();
  const apiRef = useGridApiRef();
  const stripping = useSelector((state) => state.hydraulicsStripping);
  const dispatch = useDispatch();
  const [properties, setProperties] = useState([]);
  const [invalidRows, setInvalidRows] = useState({});
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const { unit, convertUnitSystem } = useUnitConversion();
  const {
    unSavedExists,
    handleClose,
    UnsavedChangesModal
  } = useUnsavedChanges(properties, onCloseClick);

  const getPipeConfig = (configValue) => {
    return Object.keys(HYDRAULICS_TRIP_PIPE_TYPE_VALUE).find(
      (key) => HYDRAULICS_TRIP_PIPE_TYPE_VALUE[key] === configValue
    ) || configValue;
  };

  const getStripping = (configValue) => {
    return Object.keys(HYDRAULICS_TRIP_STRIPPING_TYPE_VALUE).find(
      (key) => HYDRAULICS_TRIP_STRIPPING_TYPE_VALUE[key] === configValue
    ) || configValue;
  };

  useEffect(() => {
    dispatch(listStrippingData(data?.wellsInfoId));
  }, []);

  useEffect(() => {
    if (stripping?.data) {
      const stripData = stripping.data.map((item) => {
        return {
          ...item,
          type: getStripping(item.type),
          config: getPipeConfig(item.config),
        };
      });
      setProperties(stripData);
    }
  }, [stripping?.data]);

  useEffect(() => {
    if (properties[0]?.name === '') {
      const id = properties[0]._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 0
        });
        apiRef.current.setCellMode(id, 'name', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'name');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties?.length]);

  const showError = (message, rowId) => {
    dispatch(enqueueSnackbar(message, 'error'));
    setInvalidRows(prev => ({ ...prev, [rowId]: true }));
    return false;
  };
  const validateTripDetails = (runDetails) => {
    const { speed, _id, name } = runDetails;
    const minSpeed = stripping?.units?.speed?.min;
    const maxSpeed = stripping?.units?.speed?.max;

    if (speed < minSpeed || speed > maxSpeed) return showError(`Trip Speed should be between ${minSpeed} and ${maxSpeed} ${stripping?.units?.speed?.label}`, _id);
    if (name?.length > 25) return showError('Name should not exceed more than 25 characters', _id);
    return true;
  };

  const hasDuplicateName = (arr, row) => {
    const seen = new Map();
    for (const item of arr) {
      const key = `${item.name?.toLowerCase()?.trim()}`;
      if (seen.has(key)) {
        dispatch(
          enqueueSnackbar(
            'Name already exist',
            'error',
            new Date().getTime() + Math.random()
          )
        );
        setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        return true;
      }
      seen.set(key, true);
    }
    return false;
  };

  const checkTripPropertyValues = (value) => {
    const row = value;
    const invalidFields = [];
    const isInvalidNumber = (val) => {
      // eslint-disable-next-line no-mixed-operators
      return isNaN(val) || val === '' || typeof val === 'string' && val.trim() === '';
    };
    if (row?.speed === undefined || row?.speed === null || isInvalidNumber(row.speed)) {
      invalidFields.push('Speed');
    }
    if (row?.name === undefined || row?.name.trim() === '') {
      invalidFields.push('Name');
    }

    if (invalidFields.length === 0) {
      setInvalidRows(prev => ({ ...prev, [row._id]: false }));
      return validateTripDetails(row);
    } else {
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      dispatch(
        enqueueSnackbar(
          `Please enter values for: ${invalidFields.join(', ')}`,
          'error',
          new Date().getTime() + Math.random()
        )
      );
      return false;
    }
  };

  const saveProperty = (row) => {
    if (checkTripPropertyValues(row)) {
      const validationError = '';
      if (validationError === '') {
        if (!hasDuplicateName(properties, row)) {
          const body = {
            name: row?.name,
            speed: unit !== UNIT_SYSTEM.US ? convertUnitSystem(row.speed, stripping.units?.speed?.conversionFactor) : Number(row.speed),
            type: HYDRAULICS_TRIP_STRIPPING_TYPE_VALUE[row?.type],
            config: HYDRAULICS_TRIP_PIPE_TYPE_VALUE[row?.config]
          };
          if (row?.isNewRow) {
            dispatch(createStrippingData(body, data?.wellsInfoId));
          } else {
            dispatch(updateStrippingData(body, data?.wellsInfoId, row?._id));
          }
        }
      } else {
        if (validationError.length) {
          setInvalidRows(prev => ({ ...prev, [row._id]: true }));
        }
        dispatch(
          enqueueSnackbar(
            validationError,
            'error',
            new Date().getTime() + Math.random()
          )
        );
      }
    }
  };

  const removeTripProperty = (row) => {
    if (row?.isNewRow) {
      const updatedStripData = properties.filter(item => item._id !== row?._id);
      setProperties(updatedStripData);
      dispatch(listStrippingData(data?.wellsInfoId));
    } else {
      dispatch(deleteStrippingRow(row?._id, data?.wellsInfoId));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const closeHandler = () => {
    if (unSavedExists) {
      handleClose(false);
      return;
    }
    onCloseClick();
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
          {`${selectedJob?.Rig} - ${data?.name}` || ''}
          </Grid>
          <Grid item>
          <IconButton
              onClick={closeHandler}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          const isDirty = value !== item[field];
          return {
            ...item,
            [field]: field === 'speed' ? Number(value) : (value),
            isDirty
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };

  const renderNumberValueCell = (params) => {
    return <SelectEditInputTextField numberFormatOnly {...params} updateData={updateData} />;
  };

  const renderTripTypeCell = (params) => {
    return (
      <SelectEditValueTypes
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={HYDRAULICS_TRIP_TYPE}
      />
    );
  };

  const renderTripPipeTypeCell = (params) => {
    return (
      <SelectEditValueTypes
        {...params}
        closeCellAfterSelect={true}
        updateData={updateData}
        data={HYDRAULICS_TRIP_PIPE_TYPE}
      />
    );
  };

  const handleAddStripping = () => {
    dispatch(addNewStrippingRow());
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
       <IconButton
          variant="solid"
          disabled={!row.isDirty || stripping.loading}
          onClick={() => {
            setTimeout(() => {
              saveProperty(row);
            }, 100);
          }}
        >
          <SaveRounded />
        </IconButton>
        <IconButton
          variant="solid"
          disabled={stripping.loading}
          onClick={() => {
            setDeleteAction(true);
            setDeleteRow(row);
          }}
        >
            <DeleteRounded />
        </IconButton>
      </Grid>
    );
  };

  return (
    <>
    <ModalDialog
    title={getModalTitle()}
    isOpen={isOpen}
    onClose={onCloseClick}
    dialogClass={classes.dialogModal}
  >
    <Grid container>
    <Grid item className={classes.customTool} xs={1} justifyContent="space-between" >
            <Grid item>
              <Typography variant='button' fontWeight='700' onClick={handleAddStripping} className={stripping?.loading || properties[0]?.isNewRow ? classes.disableIcon : ''}>+ Add</Typography>
            </Grid>
          </Grid>
      <Grid item xs={12}>
        <Datagrid
          apiRef={apiRef}
          data={properties}
          sx={{
            marginLeft: '-6px',
            '.MuiDataGrid-cell': {
              fontSize: '1rem',
              fontWeight: 900
            },
            '.MuiDataGrid-columnHeader': {
              fontSize: '1.2rem',
              fontWeight: '900 !important'
            }
          }}
          loading={stripping?.loading}
          columns={columns(renderValueCell, renderNumberValueCell, renderTripTypeCell, renderTripPipeTypeCell, renderActionCell, stripping?.units, unit)}
          onRowClick={(params, evt) => {
            evt.preventDefault();
            evt.stopPropagation();
          }}
          disableStickyHeader={false}
          autoHeight={false}
          autoheight
          height={'50vh'}
          getRowClassName={(params) => {
            return invalidRows[params.id] ? classes.invalidRow : '';
          }}
          pageSize={100}
          editMode="cell"
          toolbar={{
            options: {
              columns: false,
              filters: false,
              density: false,
              export: false
            },
          }}
        />
      </Grid>
    </Grid>
    {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={removeTripProperty}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
        <UnsavedChangesModal />
  </ModalDialog>
  </>
  );
};
TripPropertyModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.any
};
TripPropertyModal.defaultProps = {
  selectedJob: {}
};

export default TripPropertyModal;
