const columns = (renderActions, renderDrillFluidCell, renderMudPumpsAction) => [
  {
    field: 'fromFluidId',
    headerName: 'From',
    width: 220,
    renderEditCell: renderDrillFluidCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'toFluidId',
    headerName: 'To',
    width: 220,
    renderEditCell: renderDrillFluidCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'mudPumps',
    headerName: 'Mud Pumps',
    width: 140,
    renderCell: renderMudPumpsAction,
    editable: true,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 160,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  }
];
export default columns;
