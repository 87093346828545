import fetch from '../../api/fetch';
import { ASSET_DATA_TYPES, REDUX_ACTIONS } from '../../shared/config/constants';
import { parseError } from '../../utils/fetchErrorParser';
import { enqueueSnackbar } from './snackbar';
import { dataLoading } from './dataLoading';
import store from '../store';
import { haveSameKeys } from '../../utils/objectHelpers';

export const getAssetData =
 (
   _assetDataType = ASSET_DATA_TYPES.ACTUATORDATA,
   dateRange,
   showLoadingModal = true,
   autoReload = false,
   generateGridHeaders
 ) =>
   (dispatch) => {
     dispatch({
       type: REDUX_ACTIONS.ASSET_DATA_LOADING,
     });

     const { assetData } = store.getState((state) => state);
     const { currentUser } = store.getState((state) => state);
     const datachartSettings = JSON.parse(JSON.stringify(currentUser?.preferences?.datachartSettings));
     let lastItemInArray = {};

     if (showLoadingModal) {
       dispatch(dataLoading(true));
     }

     const collectionName = _assetDataType;
     const body = { collectionName, autoReload };

     body.preset = dateRange.preset;

     if (dateRange.end) {
       body.dateRange = {
         end: dateRange.end,
       };
     }
     if (dateRange.start) {
       body.dateRange = {
         ...body.dateRange,
         start: dateRange.start,
       };
     }
     return fetch
       .post('witsData/data', body)
       .then((result) => {
         if (!result.ok) {
           return Promise.reject(result);
         }

         return result.json();
       })
       .then((data) => {
         let dataToDisplay = data.data;
         if (autoReload) {
           let newDataList = assetData.data;
           if (data.data.length > 0) {
             newDataList = newDataList.concat(data.data);
           }

           const preset = dateRange.preset * 60;
           dataToDisplay = newDataList.reverse().slice(0, preset).reverse();
         }

         dispatch({
           type: REDUX_ACTIONS.ASSET_DATA_SUCCESS,
           payload: {
             assetData: dataToDisplay,
             paginatedElements: dataToDisplay.length,
           },
         });

         lastItemInArray = { ...dataToDisplay.slice(-1)[0] };
         delete lastItemInArray.grid_id;
         delete lastItemInArray._id;
         delete lastItemInArray.DATE;
         delete lastItemInArray.TIME;
         delete lastItemInArray.actuator;
         delete lastItemInArray?.id;

         // handle absent columns and remove them automatically in ui
         if (data.data.length > 0 && lastItemInArray?.timestamp && datachartSettings.columnVisibilityModel?.timestamp && !haveSameKeys(lastItemInArray, datachartSettings.columnVisibilityModel) && autoReload) {
           for (const key in datachartSettings.columnVisibilityModel) {
             // eslint-disable-next-line  no-prototype-builtins
             if (datachartSettings.columnVisibilityModel.hasOwnProperty(key)) {
               if (!(key in lastItemInArray)) {
                 delete datachartSettings.columnVisibilityModel[key];
                 delete datachartSettings.columnsColorDefinition[key];
                 delete datachartSettings.columnsColorDefinition[key];
                 delete datachartSettings.columnsInitialState[key];
                 datachartSettings.columnsDefinition = datachartSettings.columnsDefinition.filter((column) => column.field !== key);
                 datachartSettings.datakeys = datachartSettings.datakeys.filter((data) => data !== key);
               }
             }
           }

           for (const key in lastItemInArray) {
             // eslint-disable-next-line no-prototype-builtins
             if (lastItemInArray.hasOwnProperty(key)) {
               if (!(key in datachartSettings.columnVisibilityModel)) {
                 datachartSettings.columnVisibilityModel[key] = false;
                 datachartSettings.columnsColorDefinition[key] = '000';
               }
             }
           }

           if (generateGridHeaders) {
             generateGridHeaders(datachartSettings.columnVisibilityModel, datachartSettings?.columnsColorDefinition, datachartSettings?.datakeys);
           }

           dispatch({
             type: REDUX_ACTIONS.UPDATE_COLUMNS_USER_PREFERENCES,
             payload: datachartSettings,
           });
         }

         dispatch(dataLoading(false));
       })
       .catch(async (error) => {
         const errorMessage = await parseError(error);
         dispatch(
           enqueueSnackbar(
             errorMessage,
             'error',
             new Date().getTime() + Math.random()
           )
         );
         dispatch({
           type: REDUX_ACTIONS.ASSET_DATA_ERROR,
           payload: { ...error, message: 'Error' },
         });

         dispatch(dataLoading(false));
       });
   };

export const getEventData =
(
  _assetDataType = ASSET_DATA_TYPES.ACTUATORDATA,
  dateRange,
  showLoadingModal = true,
  autoReload = false
) =>
  (dispatch) => {
    dispatch({
      type: REDUX_ACTIONS.ASSET_DATA_LOADING,
    });

    const { assetData } = store.getState((state) => state);

    if (showLoadingModal) {
      dispatch(dataLoading(true));
    }

    const collectionName = _assetDataType;
    const body = { collectionName, autoReload };

    body.dateRange = {
      end: dateRange.end,
      start: dateRange.start,
    };

    return fetch
      .post('witsData/data', body)
      .then((result) => {
        if (!result.ok) {
          return Promise.reject(result);
        }

        return result.json();
      })
      .then((data) => {
        let dataToDisplay = data.data;
        if (autoReload) {
          let newDataList = assetData.data;
          if (data.data.length > 0) {
            newDataList = newDataList.concat(data.data);
          }

          const preset = dateRange.preset * 60;
          dataToDisplay = newDataList.reverse().slice(0, preset).reverse();
        }

        dispatch({
          type: REDUX_ACTIONS.ASSET_DATA_SUCCESS,
          payload: {
            assetData: dataToDisplay,
            paginatedElements: dataToDisplay.length,
          },
        });

        dispatch(dataLoading(false));
      })
      .catch(async (error) => {
        const errorMessage = await parseError(error);
        dispatch(
          enqueueSnackbar(
            errorMessage,
            'error',
            new Date().getTime() + Math.random()
          )
        );
        dispatch({
          type: REDUX_ACTIONS.ASSET_DATA_ERROR,
          payload: { ...error, message: 'Error' },
        });

        dispatch(dataLoading(false));
      });
  };

export const postTag =
(user, jobId, start, end, event, filterId) => (dispatch) => {
  const body = {
    userId: user.userId,
    jobId,
    startTime: start,
    endTime: end,
    event,
    filterId
  };

  fetch
    .post('events', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      dispatch(getEvents(
        jobId,
        filterId
      ));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const getEvents =
(assetDataType, filterId) => (dispatch) => {
  dispatch({
    type: REDUX_ACTIONS.EVENTS_DATA_LOADING,
  });
  const body = {
    jobId: assetDataType,
    filterId
  };

  fetch
    .post('events/list', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      const eventsData = data.values;

      dispatch({
        type: REDUX_ACTIONS.EVENTS_DATA_SUCCESS,
        payload: {
          events: eventsData,
          paginatedElements: eventsData.length,
        },
      });

      dispatch(dataLoading(false));
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const getChartPreferences = (body, disableLoader) => (dispatch) => {
  fetch
    .post('users/chartPreferences/get', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }

      return result.json();
    })
    .then((data) => {
      disableLoader();
      if (data.values && Object.keys(data.values?.details?.preferences?.datachartSettings?.columnVisibilityModel).length > 1) {
        dispatch({
          type: REDUX_ACTIONS.SET_DATA_CHART_PREFERENCES,
          payload: data.values?.details?.preferences?.datachartSettings
        });
      }
    })
    .catch(async (error) => {
      disableLoader();
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};

export const updateChartPreferences = (body) => (dispatch) => {
  return fetch
    .put('users/chartPreferences', body)
    .then((result) => {
      if (!result.ok) {
        return Promise.reject(result);
      }
      return result.json();
    })
    .catch(async (error) => {
      const errorMessage = await parseError(error);
      dispatch(
        enqueueSnackbar(
          errorMessage,
          'error',
          new Date().getTime() + Math.random()
        )
      );
    });
};
