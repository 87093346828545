import _ from 'lodash';

export const updateProperties = (obj, propNames, defaultValue) => {
  propNames.forEach(propName => {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(propName)) {
      obj[propName] = defaultValue;
    }
  });
  return obj;
};

export const updateObjectFields = (arr, idProp, idValues, fieldToUpdate, newValue) => {
  arr.forEach(function(obj) {
    if (idValues.indexOf(obj[idProp].toString()) !== -1) {
      obj[fieldToUpdate] = newValue;
    }
  });
  return arr;
};

export const getPropertyArray = (objArray, property) => {
  return objArray.map(function(obj) {
    return obj[property];
  });
};

export const convertArray = (keys, values) => {
  // eslint-disable-next-line prefer-const
  let newArray = {};

  keys.forEach((key, index) => {
    newArray[key] = values[index];
  });

  return newArray;
};

export const stringifyDeepEqual = (x, y) => {
  return JSON.stringify(x) === JSON.stringify(y);
};

export const updateArrayObjectKeys = (object, array) => {
  const newArray = [...array];
  newArray.forEach(item => {
    const field = item.field;
    if (object[field] || field === 'timestamp') {
      item.hide = false;
    } else {
      item.hide = true;
    }
  });
  return newArray;
};

export const haveSameKeys = (obj1, obj2) => {
  const keys1 = _.sortBy(_.keys(obj1));
  const keys2 = _.sortBy(_.keys(obj2));

  // Compare keys arrays
  return _.isEqual(keys1, keys2);
};

export const flattenObject = (obj) => {
  return _.reduce(obj, (result, value, key) => {
    if (typeof value === 'object' && value !== null) {
      _.forOwn(value, (nestedValue, nestedKey) => {
        result[`${key}.${nestedKey}`] = nestedValue;
      });
    } else {
      result[key] = value;
    }
    return result;
  }, {});
};
