import { UNIT_SYSTEM } from '../../../shared/config/hydraulics_constants';

const columns = (renderCell, renderActions, renderValueCell, bitDetails, currentUnit) => [
  {
    field: 'number',
    headerName: 'Number',
    width: 220,
    renderCell,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'size',
    headerName: `Size (${bitDetails?.nozzles?.label})`,
    width: 220,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(bitDetails?.nozzles?.decimals);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
