import { UNIT_SYSTEM } from '../shared/config/hydraulics_constants';
import { useAuth } from './useAuth';

const useUnitConversion = () => {
  const { user } = useAuth();
  const unit = user?.preferences?.unitSystem ? user?.preferences?.unitSystem : UNIT_SYSTEM.US;

  const convertUnitSystem = (value, conversionFactor) => (unit === UNIT_SYSTEM[unit] ? parseFloat((value / conversionFactor).toFixed(6)) : value);

  return {
    unit,
    convertUnitSystem,
  };
};

export default useUnitConversion;
