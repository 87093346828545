/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Box, Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CancelPresentationRounded, ChevronLeft, ChevronRight } from '@mui/icons-material';
import CanvasJSReact from '../../canvasjs/canvasjs.react';
import Datagrid from '../../Datagrid';
import columns from './table_config';
import useUnitConversion from '../../../../hooks/useUnitConversion';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PumpRampModel = ({
  onCloseClick,
  isOpen,
  pumpRamps,
  wellDetails,
  pumpRampUnits
}) => {
  const classes = styles();
  const [tableRow, setTableRows] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const { unit } = useUnitConversion();

  useEffect(() => {
    const records = pumpRamps?.row?.run_response?.pump_ramps?.map((record, index) => ({
      ...record,
      sbp: Number(record?.sbp?.toFixed(0)),
      _id: Math.random().toString(36).substring(2, 10),
      steps: index + 1,
    }));
    setTableRows(records);
  }, [pumpRamps]);

  const options = {
    height: 450,
    animationEnabled: true,
    theme: 'light2',
    axisY: {
      title: `SBP (${pumpRampUnits?.sbp?.label})`,
      includeZero: true,
    },
    axisY2: {
      title: `Flow In (${pumpRampUnits?.flow_in?.label})`,
    },
    axisX: {
      title: 'Steps',
    },
    data: [
      {
        type: 'column',
        showInLegend: true,
        name: `Flow In (${pumpRampUnits?.flow_in?.label})`,
        color: '#c0d0e5',
        toolTipContent: `Flow In: {y} ${pumpRampUnits?.flow_in?.label}`,
        axisYType: 'secondary',
        dataPoints: pumpRamps?.row?.run_response?.pump_ramps.map((dataPoint) => ({
          label: 'Flow In',
          y: dataPoint.flow_in,
        })),
      },
      {
        type: 'line',
        name: `SBP (${pumpRampUnits?.sbp?.label})`,
        showInLegend: true,
        color: '#1D3956',
        axisYType: 'primary',
        toolTipContent: `SBP: {y} ${pumpRampUnits?.sbp?.label}`,
        dataPoints: pumpRamps?.row?.run_response?.pump_ramps.map((dataPoint, index) => ({
          label: `${index + 1}`,
          y: dataPoint.sbp,
        })),
      },
    ],
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseClick}
      maxWidth={'lg'}
      fullWidth={true}
      style={{
        marginRight: '20px'
      }}
      PaperProps={{
        sx: {
          overflow: 'visible'
        },
      }}
    >
      <div className={classes.dialogTitle}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.3rem' }}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              {`${wellDetails?.Rig} | ${wellDetails?.Well} | ${pumpRamps?.row?.name}`}
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  onCloseClick();
                }}
              >
                <CancelPresentationRounded fontSize='large' />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>
      </div>
      <Divider />
      <Grid container item xs={showTable ? 8 : 12} alignItems="center" justifyContent="center">
        <Grid item style={{ fontWeight: 'bold', fontSize: '1.3rem', marginTop: showTable ? '3%' : '2.5%' }}>
        {'Ramp Schedule'}
        </Grid>
      </Grid>
      <Box style={{
        overflow: 'auto',
        marginTop: '-4%'
      }}>
        <Grid container marginBottom={'2%'}>
          <Grid item xs={showTable ? 8 : 12} sx={{
            p: 2,
            pt: 10,
          }}>
              <CanvasJSChart options={options} />
          </Grid>
          {!showTable && <div className={classes.menuTogglerBtn}>
            <IconButton
              onClick={() => {
                setShowTable(!showTable);
              }}>
              <ChevronLeft sx={{ fontSize: '33px' }} />
            </IconButton>
          </div>}
          {showTable && <Grid item xs={4} marginTop={'2.5%'}>
            <div className={classes.menuTogglerTableBtn}>
              <IconButton
                onClick={() => {
                  setShowTable(!showTable);
                }}>
                <ChevronRight sx={{ fontSize: '33px' }} />
              </IconButton>
            </div>
            {showTable && <Grid item xs={12}>
              <Datagrid
                fileName={`${wellDetails?.Well?.trim()}-${pumpRamps?.row?.name?.trim()}-Pump-Ramp`}
                loading={false}
                data={tableRow}
                sx={{
                  '.MuiDataGrid-cell': {
                    fontSize: '1rem',
                    padding: '2px',
                  },
                  '.MuiDataGrid-columnHeader': {
                    fontSize: '1.2rem',
                    fontWeight: '900 !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    overflow: 'visible',
                    lineHeight: '1.43rem',
                    whiteSpace: 'normal',
                  },
                  '& .MuiDataGrid-footerContainer': {
                    minHeight: '0px'
                  },
                }}
                columns={columns(pumpRampUnits, unit)}
                disableStickyHeader={true}
                autoHeight={false}
                hideFooterPagination
                hideFooterSelectedRowCount
                height={'500px'}
                getRowHeight={() => 'auto'}
                pageSize={100}
                toolbar={{
                  options: {
                    columns: false,
                    filters: false,
                    density: false,
                    export: {
                      enabled: true,
                    }
                  },
                }}
              />
            </Grid>}
          </Grid>}
        </Grid>
      </Box>
    </Dialog>
  );
};
PumpRampModel.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  pumpRamps: PropTypes.any,
  wellDetails: PropTypes.any,
  pumpRampUnits: PropTypes.any
};
PumpRampModel.defaultProps = {
  selectedJob: {}
};

export default PumpRampModel;
