import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../ModalDialog';
import Datagrid from '../Datagrid';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { columnsAssigned, columnsUnAssigned } from './table_config';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import { createMpdAsset, deleteMpdAsset, getUnassignedAssets, updateMpdAsset } from '../../../store/actions/assets';
import { assignJobAssets, exportJobAssets, getJobAssets } from '../../../store/actions/jobs';
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  CancelPresentationRounded,
  DeleteRounded,
  Https,
  PrintRounded,
  SaveRounded,
} from '@mui/icons-material';
import { UNASSIGNED_ASSET_JOB_ID, USER_TYPE } from '../../../utils/constants';
import styles from './styles';
import { renderCell } from '../Datagrid/table_config';
import SSLGenerationModal from './SSLDialogModal';
import DeleteConfirmationModal from '../../deleteConfirmationModal';
import { enqueueSnackbar } from '../../../store/actions/snackbar';
import SelectEditInputTextField from '../SelectEditInputTextField';

const usersWithEditAccess = [USER_TYPE.PREMIUM_ADMIN, USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN];

function generateRandom() {
  const length = 24;
  const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

const AssignAssetsModalDialog = ({
  isOpen,
  title,
  selectedJob,
  onCloseClick,
}) => {
  const classes = styles();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const job = useSelector((state) => state.jobs.currentJob);
  const jobData = useSelector((state) => state.jobs);
  const unassignedAssets = useSelector((state) => state.assets);
  const apiRef = useGridApiRef();
  const assignedJobsGridStateRef = useGridApiRef();
  const [assignDisabled, setAssignDisabled] = useState(true);
  const [unassignDisabled, setUnassignDisabled] = useState(true);
  const [isDirt, setIsDirt] = useState(false);
  const [showSSLModal, setShowSSLModal] = useState(false);
  const [properties, setProperties] = useState([]);
  const [deleteAction, setDeleteAction] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [invalidRows, setInvalidRows] = useState({});

  useEffect(() => {
    if (isOpen && selectedJob._id) {
      dispatch(getJobAssets(selectedJob._id));
      dispatch(getUnassignedAssets());
    }
  }, [isOpen, selectedJob]);

  useEffect(() => {
    if (!unassignedAssets.loading) {
      setProperties(unassignedAssets.data);
    }
    return () => {
      setProperties([]);
    };
  }, [unassignedAssets.data]);

  useEffect(() => {
    if (properties[0]?.SN === '') {
      const id = properties[0]?._id;
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: 1
        });
        apiRef.current.setCellMode(id, 'SN', 'edit');
        setTimeout(() => {
          apiRef.current.setCellFocus(id, 'SN');
        }, 50);
      }, 50);
      apiRef.current.setPage(0);
    }
  }, [properties.length]);

  const handleAddProperty = () => {
    const currentProperties = [...properties];
    const nextId = currentProperties.length - 1;
    const newRow = {
      SN: '',
      Type: 'HMI',
      jobId: '-1',
      grid_id: nextId + 1,
      _id: generateRandom(),
      isNewRow: true
    };
    currentProperties.unshift(newRow);
    setProperties(currentProperties);
  };

  const isFieldValid = (row, fieldName) => {
    return row?.[fieldName] !== undefined && row[fieldName] !== null && row[fieldName] !== '';
  };

  const saveProperty = (row) => {
    if (!isFieldValid(row, 'SN')) {
      dispatch(
        enqueueSnackbar(
          'Please enter SN',
          'error',
          new Date().getTime() + Math.random()
        )
      );
      setInvalidRows(prev => ({ ...prev, [row._id]: true }));
      return;
    }

    const payload = {
      SN: row.SN,
      Type: row.Type,
      jobId: row.jobId,
      _id: row._id
    };

    setInvalidRows(prev => ({ ...prev, [row._id]: false }));

    if (row.isNewRow) {
      dispatch(createMpdAsset(payload));
    } else {
      dispatch(updateMpdAsset(payload));
    }
  };

  const handleDelete = (row) => {
    if (row?.isNewRow) {
      const updatedAsset = properties.filter(item => item._id !== row?._id);
      setProperties(updatedAsset);
      dispatch(getUnassignedAssets());
    } else {
      dispatch(deleteMpdAsset(row?._id));
    }
    setDeleteAction(false);
    setDeleteRow({});
  };

  const handleDownloadCSV = () => {
    dispatch(exportJobAssets(selectedJob._id));
  };

  const getModalTitle = () => {
    return (
      <>
      <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {selectedJob?.Rig}
          </Grid>
          <Grid item>
            <IconButton
              variant="solid"
              disabled={jobData?.sslGenerate === 'loading'}
              onClick={() => {
                setShowSSLModal(true);
              }}
            >
              <Https fontSize='large' />
            </IconButton>
          <IconButton
              onClick={() => handleDownloadCSV()}
            >
              <PrintRounded fontSize='large'/>
            </IconButton>
          <IconButton
              onClick={onCloseClick}
            >
              <CancelPresentationRounded fontSize='large'/>
            </IconButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const onDialogCloseClick = () => {
    const rowsIds = [];
    for (const key of apiRef.current.getSelectedRows().keys()) {
      rowsIds.push(key);
    }
    apiRef.current.selectRows(rowsIds, false);
    onCloseClick();
    setIsDirt(false);
  };

  const createDialogButtons = () => [
    {
      text: 'Done',
      placeholder: 'Done',
      'aria-label': 'Done',
      onClick: onDialogCloseClick,
    },
  ];

  const handleAssetsAssignations = () => {
    const assets = [];
    for (const asset of apiRef.current.getSelectedRows().values()) {
      assets.push(asset);
    }
    setIsDirt(true);
    dispatch(assignJobAssets(selectedJob._id, assets));
  };

  const handleAssetsUnassignations = () => {
    const assets = [];
    for (const asset of assignedJobsGridStateRef.current
      .getSelectedRows()
      .values()) {
      assets.push(asset);
    }
    setIsDirt(true);
    dispatch(assignJobAssets(UNASSIGNED_ASSET_JOB_ID, assets));
  };

  const validateUnassigment = () => {
    setUnassignDisabled(
      !usersWithEditAccess.includes(user.type) ||
      !assignedJobsGridStateRef.current?.getSelectedRows ||
        assignedJobsGridStateRef.current.getSelectedRows().size === 0
    );
  };

  const validateAssigment = () => {
    setAssignDisabled(
      !usersWithEditAccess.includes(user.type) ||
      !apiRef.current?.getSelectedRows ||
        apiRef.current.getSelectedRows().size === 0
    );
  };

  const updateData = (id, field, value) => {
    setProperties(prevProperties => {
      const updatedProperties = prevProperties.map(item => {
        if (item._id === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      });
      return updatedProperties;
    });
  };

  const renderValueCell = (params) => {
    return <SelectEditInputTextField {...params} updateData={updateData} />;
  };

  const renderActionCell = ({ row }) => {
    return (
      <Grid container justifyContent="center">
        <IconButton
        variant="solid"
        disabled={unassignedAssets.loading}
        onClick={() => {
          saveProperty(row);
        }}
      >
        <SaveRounded />
      </IconButton>
      <IconButton
        disabled={unassignedAssets.loading}
        variant="solid"
        onClick={() => {
          setDeleteAction(true);
          setDeleteRow(row);
        }}
      >
        <DeleteRounded />
      </IconButton>
      </Grid>
    );
  };
  if (apiRef.current === null) apiRef.current = {};
  return (
    <ModalDialog
      title={title || getModalTitle()}
      isOpen={isOpen}
      onClose={onDialogCloseClick}
      buttons={isDirt ? createDialogButtons() : null}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={5.5} className={classes.subtitleContainer}>
          <Typography variant="body2">Unassigned</Typography>
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={5.5} className={classes.subtitleContainer}>
          <Typography variant="body2">Assigned</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Grid item xs={5.5}>
          <Datagrid
            data={properties}
            loading={unassignedAssets.loading}
            columns={columnsUnAssigned(renderCell, renderActionCell, renderValueCell, user.type)}
            checkboxSelection
            disableSelectionOnClick
            apiRef={apiRef}
            onSelectionModelChange={validateAssigment}
            getRowClassName={(params) => {
              return invalidRows[params.id] ? classes.invalidRow : '';
            }}
            disableStickyHeader={true}
            autoHeight={false}
            height={'400px'}
            toolbar={{
              options: {
                columns: false,
                filters: true,
                export: false,
              },
            }}
          />
           {(user.type === USER_TYPE.PREMIUM_ADMIN || user.type === USER_TYPE.PREMIUM_REALTIME_MPD_ADMIN) && <Box>
              <Typography style={{ cursor: 'pointer', marginLeft: '10px' }}
                variant='button'
                fontWeight='700'
                className={unassignedAssets.loading ? classes.disableIcon : ''}
                onClick={handleAddProperty}>
                + Add
              </Typography>
            </Box> }
            <br />
        </Grid>
        <Grid item xs={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton
            variant="contained"
            onClick={handleAssetsAssignations}
            disabled={assignDisabled}
          >
            <ArrowForwardIosRounded />
          </IconButton>
          <IconButton
            variant="contained"
            onClick={handleAssetsUnassignations}
            disabled={unassignDisabled}
          >
            <ArrowBackIosRounded />
          </IconButton>
        </Grid>
        <Grid item xs={5.5} style={{ marginTop: '-45px' }}>
          <Datagrid
            data={job.assets}
            columns={columnsAssigned(renderCell)}
            checkboxSelection
            disableSelectionOnClick
            apiRef={assignedJobsGridStateRef}
            onSelectionModelChange={validateUnassigment}
            disableStickyHeader={true}
            autoHeight={false}
            height={'400px'}
          />
        </Grid>
      </Grid>
      {
        showSSLModal && <SSLGenerationModal
        open={showSSLModal}
        handleClose={() => {
          setShowSSLModal(false);
        }}
        />
      }
      {
          deleteAction && <DeleteConfirmationModal
            open={deleteAction}
            handleDelete={handleDelete}
            handleClose={setDeleteAction}
            deleteRow={deleteRow}
          />
        }
    </ModalDialog>
  );
};

// AssignAssetsModalDialog.defaultProps = {
//   selectedJob: {},
// };

AssignAssetsModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  selectedJob: PropTypes.object,
};

export default AssignAssetsModalDialog;
