import { combineReducers } from 'redux';
import authReducer from './authorization';
import auditLogsReducer from './auditLogs';
import jobsReducer from './jobs';
import alarmsReducer from './alarms';
import partReducer from './parts';
import companyReducer from './companies';
import sellerReducer from './sellers';
import userReducer from './users';
import assetDataReducer from './assetData';
import assetsReducer from './assets';
import ordersReducer from './orders';
import ticketsReducer from './tickets';
import quotesReducer from './quotes';
import snackbarReducer from './snackbar';
import menuReducer from './menu';
import searchBoxReducer from './searchBox';
import dataLoadingReducer from './dataLoading';
import epicorCustomerReducer from './epicor/epicorCustomers';
import epicorPartReducer from './epicor/epicorParts';
import epicorPriceListsReducer from './epicor/epicorPriceLists';
import epicorSaleOrdersReducer from './epicor/epicorSaleOrders';
import epicorWorkForceReducer from './epicor/epicorWorkForce';
import epicorBinsReducer from './epicor/epicorBins';
import epicorTrucksReducer from './epicor/epicorTrucks';
import epicorSyncMobileDevicesReducer from './epicor/epicorSyncMobileDevices';
import epicorSendBinRemindersReducer from './epicor/epicorSendBinReminders';
import epicorReleasesReducer from './epicor/epicorReleases';
import epicorOrderDetailsReducer from './epicor/epicorOrderDetails';
import transactionHistoryReducer from './transactionHistory';
import alarmHistoryReducer from './alarmHistory';
import wellsReducer from './wells';
import marketplaceTransactionsReducer from './marketplaceTransactions';
import rigsReducer from './rigs';
import assetTypesReducer from './assetTypes';
import enverusWellsReducer from './enverus/wells';
import checkInsReducer from './checkins';
import actuatorReducer from './actuatorReport';
import marketplaceReducer from './marketplace';
import wellsSurveyReducer from './wellsSurvey';
import wellsDrillStringReducer from './wellsDrillString';
import wellsDrillFluidReducer from './wellsDrillFluid';
import wellsGeometryReducer from './wellsGeometry';
import hydraulicsReducer from './hydraulics';
import marketplaceLogsReducer from './marketplaceLogs';
import checkInsDrillReducer from './checkInsDrill';
import wellsWindowReducer from './wellsWindow';
import dbwVsTargetReducer from './dbwVsTarget';
import spendReducer from './spend';
import hydraulicsStrippingReducer from './hydraulicsStripping';
import favoritePartsReducer from './favoriteParts';
import hydraulicsLogsReducer from './hydraulicsLogs';

const appReducer = combineReducers({
  currentUser: authReducer,
  jobs: jobsReducer,
  alarms: alarmsReducer,
  parts: partReducer,
  companies: companyReducer,
  sellers: sellerReducer,
  users: userReducer,
  assetData: assetDataReducer,
  assets: assetsReducer,
  orders: ordersReducer,
  tickets: ticketsReducer,
  quotes: quotesReducer,
  snackbar: snackbarReducer,
  dataLoading: dataLoadingReducer,
  epicorCustomers: epicorCustomerReducer,
  epicorParts: epicorPartReducer,
  epicorPriceLists: epicorPriceListsReducer,
  epicorSaleOrders: epicorSaleOrdersReducer,
  epicorWorkForce: epicorWorkForceReducer,
  epicorBins: epicorBinsReducer,
  epicorTrucks: epicorTrucksReducer,
  epicorReleases: epicorReleasesReducer,
  epicorOrderDetails: epicorOrderDetailsReducer,
  epicorSyncMobileDevices: epicorSyncMobileDevicesReducer,
  epicorSendBinReminders: epicorSendBinRemindersReducer,
  menu: menuReducer,
  searchBox: searchBoxReducer,
  auditLogs: auditLogsReducer,
  hydraulicsLogs: hydraulicsLogsReducer,
  transactionHistory: transactionHistoryReducer,
  alarmHistory: alarmHistoryReducer,
  wells: wellsReducer,
  marketplaceTransactions: marketplaceTransactionsReducer,
  wellsSurvey: wellsSurveyReducer,
  rigs: rigsReducer,
  assetTypes: assetTypesReducer,
  enverusWells: enverusWellsReducer,
  checkIns: checkInsReducer,
  actuatorReport: actuatorReducer,
  wellsDrillString: wellsDrillStringReducer,
  wellsDrillFluid: wellsDrillFluidReducer,
  wellsGeometry: wellsGeometryReducer,
  wellsWindow: wellsWindowReducer,
  hydraulics: hydraulicsReducer,
  marketplace: marketplaceReducer,
  marketplaceLogs: marketplaceLogsReducer,
  checkInsDrill: checkInsDrillReducer,
  dbwVsTarget: dbwVsTargetReducer,
  spend: spendReducer,
  hydraulicsStripping: hydraulicsStrippingReducer,
  favoritePart: favoritePartsReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
