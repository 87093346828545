import { UNIT_SYSTEM } from '../../shared/config/hydraulics_constants';

const columns = (renderCell, renderActions, nonEditableFields, renderValueCell, surveyUnit, currentUnit) => [
  {
    field: 'md',
    headerName: `MD (${surveyUnit?.md?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.md?.decimals);
    },
  },
  {
    field: 'inc',
    headerName: `Inc (${surveyUnit?.inc?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.inc?.decimals);
    },
  },
  {
    field: 'az',
    headerName: `Az (${surveyUnit?.az?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: true,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.az?.decimals);
    },
  },
  {
    field: 'tvd',
    headerName: `TVD (${surveyUnit?.tvd?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.tvd?.decimals);
    },
  },
  {
    field: 'ns',
    headerName: `NS (${surveyUnit?.ns?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.ns?.decimals);
    },
  },
  {
    field: 'ew',
    headerName: `EW (${surveyUnit?.ew?.label})`,
    width: 150,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.ew?.decimals);
    },
  },
  {
    field: 'dls',
    headerName: `DLS (${surveyUnit?.dls?.label})`,
    width: 220,
    renderEditCell: renderValueCell,
    editable: false,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      if (isNaN(value)) {
        return params.value;
      }
      return currentUnit !== UNIT_SYSTEM.US ? value : value.toFixed(surveyUnit.dls?.decimals);
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    headerAlign: 'center',
    align: 'center',
    renderCell: renderActions,
  },
];
export default columns;
