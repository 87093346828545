import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import AspectRatioRounded from '@mui/icons-material/AspectRatioRounded';
import { Box, IconButton, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { USER_TYPE } from '../../../../utils/constants';
import SendBinRemindersModal from './SendBinRemindersModal';
import CardMedia from '@mui/material/CardMedia';
import Premium from '../../../../assets/images/PRO logo PMS green only.svg';
import { useState } from 'react';
import { REDUX_ACTIONS } from '../../../../shared/config/constants';
import styles from './styles';
import useAspectRatio from '../../../../hooks/useAspectRatio';
import { epicorSendBinReminders } from '../../../../store/actions/epicor/epicorSendBinReminders';

const binRemindersPermitedRoles = [
  USER_TYPE.PREMIUM_ADMIN,
  USER_TYPE.PREMIUM_FIELD_TICKETING_ADMIN
];

const SendBinReminders = () => {
  const [open, setOpen] = useState(false);
  const classes = styles();
  const user = useSelector((state) => state.currentUser);
  const epicorBins = useSelector((state) => state.epicorBins);
  const sendBinReminders = useSelector((state) => state.epicorSendBinReminders);
  const dispatch = useDispatch();
  const isAdminUser = binRemindersPermitedRoles.includes(user.type);

  if (!isAdminUser) {
    return null;
  }

  const handleAspectRatio = useAspectRatio();
  const { preferences } = useSelector((state) => state.currentUser);

  const handleOpenModal = () => {
    setOpen(true);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    dispatch({
      type: REDUX_ACTIONS.EPICOR_SEND_BIN_REMINDERS_IDLE,
      payload: {
        message: '',
      },
    });
  };

  const handleSendBinReminders = () => {
    dispatch(epicorSendBinReminders());
  };

  return (<Box>
    <Box className={classes.container}>
      {user.type !== USER_TYPE.PREMIUM_FIELD_TICKETING_MANAGER && (
        <IconButton
          onClick={() => {
            handleOpenModal();
          }}
          disabled={epicorBins.loading === true}
        >
          <NotificationsRoundedIcon />
        </IconButton>
      )}
      <IconButton
        onClick={() => {
          handleAspectRatio(!preferences.aspectChanged);
        }}
      >
        <AspectRatioRounded />
      </IconButton>
    </Box>
    {open
      ? (
        <SendBinRemindersModal
          open={open}
          onClose={handleCloseModal}
          handleClose={handleCloseModal}
          cta2Label={'Send'}
          cta2Disabled={sendBinReminders.binRemindersStatus === 'loading'}
          cta2Action={handleSendBinReminders}
          showCta2={sendBinReminders.binRemindersStatus !== 'success'}
          showDateTimeRange={false}
        >
          <CardMedia
            component="img"
            image={Premium}
            className={classes.logo}
          />

          <Typography variant="h3" gutterBottom component="div" className={classes.title}>
            Send Bin Reminder Emails?
          </Typography>

          {sendBinReminders.binRemindersResponseMessage !== ''
            ? <Typography variant="h5" gutterBottom component="div" className={classes.statusMessage}>
              {sendBinReminders.binRemindersResponseMessage}
            </Typography>
            : <div style={{
              height: 40,
            }}></div>
          }
        </SendBinRemindersModal>
      )
      : null}
  </Box>
  );
};

export default SendBinReminders;
