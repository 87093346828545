import { useSelector } from 'react-redux';

const columns = (renderCell, renderJsonField, renderOrderField) => {
  const user = useSelector((state) => state.currentUser);

  return [
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 250,
      hide: false,
      renderCell,
      headerAlign: 'center'
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      width: 300,
      renderCell,
      headerAlign: 'center',
    },
    {
      field: 'BinNum',
      headerName: 'Box Name',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'division',
      headerName: 'Division',
      width: 300,
      renderCell,
      headerAlign: 'center',
      align: 'center',
      sortable: false
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      renderCell,
      headerAlign: 'center'
    },
    {
      field: 'payload',
      headerName: 'Input',
      width: 300,
      renderCell: renderJsonField,
      headerAlign: 'center',
      align: 'left',
      flex: user.preferences.aspectChanged ? 1 : 'none'
    },
    {
      field: 'response',
      headerName: 'Response',
      width: 300,
      renderCell: renderJsonField,
      headerAlign: 'center',
      align: 'left',
      flex: user.preferences.aspectChanged ? 1 : 'none'
    },
    {
      field: 'orderNumber',
      headerName: 'Order Number',
      width: 200,
      renderCell: renderOrderField,
      headerAlign: 'center',
      align: 'center'
    },
  ];
};

export default columns;
