import { REDUX_ACTIONS } from '../../shared/config/constants';
const initialState = {
  loading: false,
  deleting: false,
  data: [],
  units: {},
  errorMessage: '',
  loadingDelete: false,
  saved: false,
  paginatedElements: 0,
  details: {
    data: [],
    units: {},
    paginatedElements: 0
  },
  bit_details: {
    data: [],
    units: {},
    paginationItemClasses: 0
  }
};
const wellsDrillStringReducer = (state = initialState, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
        units: { ...action.payload.units },
        paginatedElements: action.payload.paginatedElements,
        errorMessage: ''
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.message,
        data: []
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_LIST_ERROR_NOTIFIED:
      return {
        ...state,
        errorMessage: '',
        data: []
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_SET_ID:
      return {
        ...state,
        drillStringId: action.payload.data
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_RESET:
      return {
        ...initialState
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_LOADING:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          data: [...action.payload.data],
          units: { ...action.payload.units },
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_DETAILS_LIST_ERROR:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          errorMessage: action.payload.message,
          data: []
        }
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_LOADING:
      return {
        ...state,
        bit_details: {
          ...state.bit_details,
          loading: true,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        bit_details: {
          ...state.bit_details,
          loading: false,
          data: { ...action.payload.data[0] },
          units: { ...action.payload.units },
          paginatedElements: action.payload.paginatedElements,
          errorMessage: ''
        }
      };
    case REDUX_ACTIONS.WELLS_DRILL_STRING_BIT_DETAILS_LIST_ERROR:
      return {
        ...state,
        bit_details: {
          ...state.bit_details,
          loading: false,
          errorMessage: action.payload.message,
          data: {}
        }
      };
    default:
      return state;
  }
};
export default wellsDrillStringReducer;
