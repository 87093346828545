import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

const columns = (mudDisplacementUnit) => [
  {
    field: 'strokes',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Strokes
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        count
      </Typography>
      </Box>
    ),
    width: 65,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'flow_rate',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Rate
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.flow_rate?.label})
      </Typography>
      </Box>
    ),
    width: 50,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'volume',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Volume
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.volume?.label})
      </Typography>
      </Box>
    ),
    width: 65,
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(mudDisplacementUnit?.volume?.decimals);
    },
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'front_position',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Front
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Depth ({mudDisplacementUnit?.front_position?.label})
      </Typography>
      </Box>
    ),
    width: 80,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'front_direction',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Front
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Direction
      </Typography>
      </Box>
    ),
    width: 75,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      if (params?.row?.front_direction === 'down') {
        return (
          <IconButton>
            <ArrowDownwardOutlined />
          </IconButton>
        );
      }
      if (params?.row?.front_direction === 'up') {
        return (
          <IconButton>
            <ArrowUpwardOutlined />
          </IconButton>
        );
      }
      return null;
    },
  },
  {
    field: 'icp_ecd',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ICP ECD
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.icp_ecd?.label})
      </Typography>
      </Box>
    ),
    width: 85,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'lp_ecd',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        LP ECD
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.lp_ecd?.label})
      </Typography>
      </Box>
    ),
    width: 85,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'bh_ecd',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        BH ECD
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.bh_ecd?.label})
      </Typography>
      </Box>
    ),
    width: 85,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'sbp',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        SBP
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.sbp?.label})
      </Typography>
      </Box>
    ),
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(mudDisplacementUnit?.sbp?.decimals);
    },
    width: 50,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'static_sbp',
    renderHeader: () => (
      <Box>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        Static SBP
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}>
        ({mudDisplacementUnit?.static_sbp?.label})
      </Typography>
      </Box>
    ),
    width: 80,
    valueFormatter: (params) => {
      if (params.value === null || params.value === undefined || params.value === '') {
        return '';
      }
      const value = Number(params.value);
      return isNaN(value) ? params.value : value.toFixed(mudDisplacementUnit?.static_sbp?.decimals);
    },
    headerAlign: 'center',
    align: 'center',
  },
];
export default columns;
