export const getDateRange = (type) => {
  const now = new Date();
  let startDate, endDate;

  switch (type) {
    case 'year': {
      startDate = new Date(Date.UTC(now.getFullYear(), 0, 1)); // January 1st of the current year
      endDate = new Date(Date.UTC(now.getFullYear(), 11, 31, 23, 59, 59, 999)); // December 31st of the current year
      break;
    }

    case 'month': {
      startDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1)); // First day of the current month
      endDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999)); // Last day of the current month
      break;
    }

    case 'week': {
      const dayOfWeek = now.getUTCDay(); // Sunday - Saturday : 0 - 6
      const startOfWeek = new Date(now);
      startOfWeek.setUTCDate(now.getUTCDate() - dayOfWeek); // Set to the most recent Sunday
      startDate = new Date(Date.UTC(startOfWeek.getUTCFullYear(), startOfWeek.getUTCMonth(), startOfWeek.getUTCDate(), 0, 0, 0)); // Start of the week
      endDate = new Date(Date.UTC(startOfWeek.getUTCFullYear(), startOfWeek.getUTCMonth(), startOfWeek.getUTCDate() + 6, 23, 59, 59, 999)); // End of the week (next Saturday)
      break;
    }

    default:
      throw new Error("Invalid type. Please specify 'year', 'month', or 'week'.");
  }

  return {
    type: type === 'year' ? 'year' : 'month',
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};

export const checkDateDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDifference = end - start;
  const dayDifference = timeDifference / (1000 * 3600 * 24);
  return dayDifference > 30 ? 'year' : 'month';
};
